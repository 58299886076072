<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ผู้ใช้งานระบบ-ข้าราชการครูและบุคลการทางการศึกษา"
        class="px-5 py-3"
      >
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_temporarys"
          :search="search"
        >
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>  
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      editpersonnel_temporarydialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      editpersonnel_temporary: {},
      search: "",
      pagination: {},
      headers: [
        { text: "วิทยาลัย", align: "center", value: "college_name" },
        { text: "คำนำหน้าชื่อ", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "เงินเดือน", align: "center", value: "salary_s" },
        { text: "วิทยฐานะ", align: "center", value: "rang_name" },
        { text: "วันเกิด", align: "center", value: "brith_day" },
        { text: "เดือนเกิด", align: "center", value: "brith_month" },
        { text: "ปีเกิด", align: "center", value: "brith_year" },
        { text: "วันที่บรรจุ", align: "center", value: "date_app" },
        { text: "สาขาวิชา", align: "center", value: "branch_name_th" },
        { text: "เกษียณ", align: "center", value: "retrire_year" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      personnel_temporarystatus: [],
      userstatus: {},
      data_syslog: {},
      userinfos:[],
    };
  },
  async mounted() {
   await this.userQuery();
   await this.personnel_temporaryQueryAll();
  },
  methods: {
    async userQuery(){
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      
      let result = await this.$http
        .post("user.php", {
          ApiKey: this.ApiKey,
          user_ID: userSession.user_ID
        })  
      this.userinfos = result.data;
      
    },


    async personnel_temporaryQueryAll() {
      this.loading = true;  
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          region_ID: this.userinfos.region_ID
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async personnel_temporaryEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporarydialog = true;
    },
    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;
        let result = await this.$http.post(
          "personnel_temporary.update.php",
          this.editpersonnel_temporary
        );
        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });

          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update_password";
          this.data_syslog.page_log = "personnel";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.editpersonnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);

          this.personnel_temporaryQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
